import React, {FC} from "react";
import './MobileNavigation.scoped.scss';
import {Button, CustomLink, SecondaryButton,} from "../../common";
import {cn, resultIf, RouteDictionary} from "../../../utils";
import {useLoggedInStatus} from "../../../hooks";
import {SecondaryButtonColor} from "../../common/buttons/decorators/SecondaryButton/SecondaryButton";

interface Props {
    active: boolean;
    onNavigated: () => void;
}

const MobileNavigation: FC<Props> = ({active, onNavigated}) => {
  const isLoggedIn = useLoggedInStatus();
  return (
    <nav className={cn(
      "mobile-navigation",
      resultIf(active, "active")
    )}>
      <div className="mobile-navigation__inner">
        <ul className="mobile-navigation__list">
          { !isLoggedIn &&
          <li className="mobile-navigation__login">
            Войти как
            <SecondaryButton color={SecondaryButtonColor.GREEN}>
              <CustomLink to={RouteDictionary.ENTER} >
                Инвестор
              </CustomLink>
            </SecondaryButton>
            или
            <SecondaryButton color={SecondaryButtonColor.ORANGE}>
              <Button onClick={() => console.log("123")}>
                Заемщик
              </Button>
            </SecondaryButton>
          </li> }
          {/*<li className="mobile-navigation__element">*/}
          {/*    <CustomLink*/}
          {/*        onClick={onNavigated}*/}
          {/*        className="mobile-navigation__link"*/}
          {/*        to={RouteDictionary.HOW_IT_WORKS}*/}
          {/*    >*/}
          {/*        Как это работает*/}
          {/*    </CustomLink>*/}
          {/*</li>*/}
          {/*<li className="mobile-navigation__element">*/}
          {/*    <CustomLink*/}
          {/*        onClick={onNavigated}*/}
          {/*        className="mobile-navigation__link"*/}
          {/*        to={RouteDictionary.NEWS}*/}
          {/*    >*/}
          {/*        Новости*/}
          {/*    </CustomLink>*/}
          {/*</li>*/}
          {/*<li className="mobile-navigation__element">*/}
          {/*    <CustomLink*/}
          {/*        onClick={onNavigated}*/}
          {/*        className="mobile-navigation__link"*/}
          {/*        to={RouteDictionary.PLATFORM}*/}
          {/*    >*/}
          {/*        О платформе*/}
          {/*    </CustomLink>*/}
          {/*</li>*/}
          {/*<li className="mobile-navigation__element">*/}
          {/*    <CustomLink*/}
          {/*        onClick={onNavigated}*/}
          {/*        className="mobile-navigation__link"*/}
          {/*        to={RouteDictionary.FAQ}*/}
          {/*    >*/}
          {/*        Помощь*/}
          {/*    </CustomLink>*/}
          {/*</li>*/}
          { isLoggedIn && <>
            {/*<li className="mobile-navigation__element">*/}
            {/*    <CustomLink*/}
            {/*        onClick={onNavigated}*/}
            {/*        className="mobile-navigation__link"*/}
            {/*        to={RouteDictionary.REFERRAL}*/}
            {/*    >*/}
            {/*        Реферальный доход*/}
            {/*    </CustomLink>*/}
            {/*</li>*/}
            <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.DOCUMENTS_TO_PROJECT}
              >
                Документы
              </CustomLink>
            </li>
            <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.AUTOINVEST}
              >
                Автоинвестирование
              </CustomLink>
            </li>
            <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.PROFILE}
              >
                Профиль
              </CustomLink>
            </li>
            <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.LOGOUT}
              >
                Выйти из аккаунта
              </CustomLink>
            </li>
          </> }
        </ul>
        {/*<div className="mobile-navigation__money">*/}
        {/*    <TertiaryButton expanded color={TertiaryButtonColor.GREEN}>*/}
        {/*        <CustomLink onClick={onNavigated} to={RouteDictionary.DEPOSIT}>*/}
        {/*            Пополнить*/}
        {/*        </CustomLink>*/}
        {/*    </TertiaryButton>*/}
        {/*    <TertiaryButton expanded color={TertiaryButtonColor.GRAY}>*/}
        {/*        <CustomLink onClick={onNavigated} to={RouteDictionary.WITHDRAW}>*/}
        {/*            Вывести*/}
        {/*        </CustomLink>*/}
        {/*    </TertiaryButton>*/}
        {/*</div>*/}
      </div>
    </nav>
  );
};

export default MobileNavigation;