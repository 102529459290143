import React, {useContext} from "react";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import './ProjectAutoInvestResults.scoped.scss';
import {InputMoney, Money, Toggle} from "../../../common";
import {cn, resultIf} from "../../../../utils";
import {useParams} from "react-router-dom";
import {useWindowWidth} from "../hooks";
import {formatDate} from "../../../../formatters";
import {Application, isFundRaising, Money as MoneyModel, ProjectStatus} from "../../../../api-client";

interface Props {
    result: Application;
    maxSum: MoneyModel;
    minSum: MoneyModel;
    activeToggle: boolean;
}

const ProjectAutoInvestResults = (props: Props) => {
  const {
    result,
    minSum,
    maxSum,
    activeToggle,
  } = props;
  const isMobile = useWindowWidth();
  const {confirmationUuid} = useParams();
  const project = useContext(DetailedProjectContext).project;
  let date = null;
  if (project.status === ProjectStatus.AUTO_INVESTMENT && project.conductedAutoInvestmentDate) date = formatDate(project.conductedAutoInvestmentDate, "DD.MM.YYYY HH:mm");
  else if (project.accounting.scheduledAutoInvestmentDate) date = formatDate(project.accounting.scheduledAutoInvestmentDate, "DD.MM.YYYY HH:mm");
  return (
    <div
      className={cn("project-invest-results", resultIf(isFundRaising(project.status), "detailed-project-autoinvest-results"))}>
      <div
        className={cn("project-invest-results__container project-invest-results__ai-container",
          resultIf(confirmationUuid !== undefined, "project-invest-results__container-none"))}>
        <div className={"project-invest-results__toggle-container"}>
          <Toggle active={activeToggle} onToggled={() => {
          }} size={isMobile ? 's' : 'm'} disabled={true}/>
          <div
            className={cn("project-invest-results__toggle-title", resultIf(false, "active"))}>Вход
            в проект по АИ
          </div>
        </div>
        {activeToggle && <div className={"project-invest-results__money project-invest-results__money--additional"}>
          Результаты проведения АИ
        </div>}

        {activeToggle && <div className="project-invest-results__main">
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Дата проведения АИ:
            </div>
            <div>
              {date ? date : "Дата не указана"}
            </div>
          </div>
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Начальная сумма заявки:
            </div>
            <div>
              <Money money={result.result.initialOffer}/>
            </div>
          </div>
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Остаток лимита:
            </div>
            <div>
              <Money money={result.result.totalInvestedAfter}/>
            </div>
          </div>
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Доступно:
            </div>
            <div>
              <Money money={result.details.balanceBefore}/>
            </div>
          </div>
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Остаток суммы сбора:
            </div>
            <div>
              <Money money={result.details.fundsLeft}
              />
            </div>
          </div>
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Минимальная инвестиция:
            </div>
            <div>
              <Money
                money={result.details.minInvestment}
              />
            </div>
          </div>
          <form className="project-invest-results__input">
            <div className="project-invest-results__input-additional">
              Минимальная сумма
            </div>
            <InputMoney
              money={minSum}
              onValueChanged={() => {}}
            />
            <div
              className="project-invest-results__input-additional">
              Максимальная сумма
            </div>
            <InputMoney
              money={maxSum}
              onValueChanged={() => {}}
            />
          </form>
          <div className={"project-invest-results__money project-invest-results__money--additional"}>
            <div>
              Сумма заявки:
            </div>
            <div>
              <Money money={result.money}/>
            </div>
          </div>

          {result.result.messages.map((message) => {
            return <div key={message} className={"project-invest-results__money"}>
              {message}
            </div>;
          })}
          {result.result.declineMessages.map((message) => {
            return <div key={message} className={"project-invest-results__money project-invest-results__money__error"}>
              {message}
            </div>;
          })}
        </div>}
      </div>
    </div>
  );
}
;

export default ProjectAutoInvestResults;
