import React, {FC, useEffect, useState} from "react";
import {Notification} from "../../../../api-client";
import {useDropdown, useToggle} from "../../../../hooks";
import './NotificationContent.scoped.scss';
import {cn, resultIf} from "../../../../utils";

interface Props {
    notification: Notification;
}

const maxHeightPx = 80;

const NotificationContent: FC<Props> = ({notification}) => {
  const [active, toggle] = useToggle();
  const [contentElement, height] = useDropdown(active, maxHeightPx);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    if (!active && contentElement.current!.clientHeight > maxHeightPx) {
      setIsOverflowing(true);
    }
    return () => {
      setIsOverflowing(false);
    };
  }, [height]);

  const handleClick = () => {
    if (isOverflowing || active) {
      toggle();
    }
  };

  return (
    <div
      className={cn(
        "notification__content",
        resultIf(isOverflowing, "overflow"),
        resultIf(active, "active")
      )
      }
      onClick={handleClick}
      style={{maxHeight: height}}
    >
      <div className="notification__content--inner" ref={contentElement} dangerouslySetInnerHTML={{__html:notification.content.replace(/\n/g, "<br />")}}>
      </div>
    </div>
  );
};

export default NotificationContent;