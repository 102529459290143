import {ApiClientInterface} from "./clients";
import {Collection, Notification} from "./models";
import buildQuery from "../utils/router/buildQuery";

export class NotificationManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getList(page: number, perPage: number, ): Promise<Collection<Notification>> {
    const query = buildQuery({page, perPage});
    return this.apiClient.get(`/?${query}`);
  }
}