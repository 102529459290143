import React, {SyntheticEvent, useMemo, useState} from "react";
import {AuthInput} from "../../../views/auth/Enter/Enter";
import {Errors as ValidationErrors} from "../../../utils/";
import {Button, InputErrors, InputPassword, PrimaryButton} from "../../common";
import {useEffectOnUpdate} from "../../../hooks";
import {createRegistrationManager} from "../../../di";
import {LenderInput, RegisteredUser} from "../../../api-client";
import {PrimaryButtonColor} from "../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {useSearchParams} from "react-router-dom";

type Props = {
    input: AuthInput;
    onRegister: () => void;
}

type PasswordErrors = {
    password?: ValidationErrors;
    confirmPassword?: ValidationErrors;
}

const CreatePassword = ({input, onRegister}: Props) => {
  const [errors, setErrors] = useState<PasswordErrors>({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams({});
  const referrerCode = searchParams.get('r');
  const lenderInput = useMemo((): LenderInput => {
    const lenderInput: LenderInput = {phone: input.phone, password: input.password};
    if (referrerCode) {
      return {...input, referrerCode};
    }
    return lenderInput;
  }, [input]);

  const validateForm = () => {
    const errors: PasswordErrors = {};
    if (!input.password) {
      errors.password = ["Пароль обязателен для ввода"];
    }
    if (input.password !== confirmPassword) {
      errors.confirmPassword = ["Пароли не совпадают"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateForm();
  }, [input.password, confirmPassword]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      const manager = createRegistrationManager();
      try {
        const user: RegisteredUser = await manager.registerLender(lenderInput);
        if (user) {
          onRegister();
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <div>
        Придумайте свой пароль
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__group">
          <InputPassword
            password={input.password}
            onInput={input.setPassword}
            title="Пароль"
            id="password"
          />
          { errors.password && <InputErrors errors={errors.password} /> }
        </div>
        <div className="form__group">
          <InputPassword
            password={confirmPassword}
            onInput={setConfirmPassword}
            title="Повторите пароль"
            id="password-confirm"
          />
          { errors.confirmPassword && <InputErrors errors={errors.confirmPassword} /> }
        </div>
        <div className="form__group">
          <PrimaryButton expanded={true} large={true} color={PrimaryButtonColor.GREEN}>
            <Button type="submit">
              Отправить
            </Button>
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default CreatePassword;