import React, {useEffect, useRef, useState} from "react";
import './CopyToClipboard.scoped.scss';
import copyToClipboardImage from "../../../../assets/images/svg/copy.svg";
import {cn} from "../../../../utils";

type Props = {
    textToCopy: string;
    withDescription?: boolean | undefined;
}

const CopyToClipboard = ({textToCopy, withDescription}: Props) => {
  const [active, setActive] = useState(false);
  const timout=useRef<ReturnType<typeof setTimeout> | null >(null);
  const copyText = () => {
    setActive(true);
    const el = document.createElement("textarea");
    el.value = textToCopy;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    timout.current = setTimeout(() => {
      setActive(false);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timout.current) {
        clearTimeout(timout.current);
      }
    };
  }, []);

  return (
    <>
      <div
        className={cn(
          "copy-to-clipboard",
          `${withDescription ? "copy-to-clipboard--full" : ""}`
        )}
        onClick={copyText}
      >
        <img src={copyToClipboardImage} alt="Copy image" />
        { withDescription && <div>
          Копировать
        </div> }
      </div>
      {active && <p>Скопировано</p>}
    </>
  );
};

export default CopyToClipboard;