import React from "react";
import './BalanceStats.scoped.scss';
import {useWindowWidth} from "../../detailed-project/hooks";
import {Image, Money, Tooltip} from "../../../common";
import moneyIcon from "../../../../assets/images/svg/money.svg";
import {Block, BlockDropdown} from "../../../layout";
import {useMyMoneyData} from "../../../../hooks/useMyMoneyData";
import {declOfNum} from "../../../../utils";
import {useProfileData} from "../../../../hooks";


const BalanceStats = () => {
  const isMobile = useWindowWidth();
  const stats = useMyMoneyData();
  const profile = useProfileData();

  const balanceAccount = [
    {title: 'Зачислено', count: stats.total.depositAmount, string: '',
      content: 'Сумма ваших пополнений '},
    {title: 'Инвестировано', count: stats.total.invested, string: '-',
      content: 'Сумма ваших инвестиций с вознаграждением за инвестиции по проектам, в которых была оплата по Договору'},
    {title: 'Возвращено', count: stats.total.comebackAmount, string: '',
      content: 'Сумма взысканий на вашу долю за вычетом вознаграждений за доход и инкассо платежей кредитору'},
    {title: 'Выведено', count: stats.total.withdrawAmount, string: '-',
      content: 'Сумма средств, выведенных на ваш банковский счет'},
  ];
  const balanceValue = profile.balance.amount + stats.total.reservedInvestment.amount;

  const balanceTotal = [
    {title: 'Баланс', count: balanceValue, string: ' = ',
      content: 'Сумма средств на вашем счете включая средства, зарезервированные в проекты'},
    {title: `Зарезервировано (${stats.fundRaising.projects} ${declOfNum(stats.fundRaising.projects, ["проект", "проекта", "проектов"])})`,
      count: stats.total.reservedInvestment.amount, string: '-',
      content: 'Сумма средств, зарезервированных в проектах, по которым еще не было оплаты по Договору'},
    {title: 'Доступно', count: profile.balance.amount, string: '=',
      content: 'Разность "Баланс" и "Зарезервировано"'},
  ];
  const header = () => {
    return (
      <div className="balance-stats__header">
        <div className="balance-stats__icon">
          <Image src={moneyIcon} alt="Money icon"/>
        </div>
        <div className="balance-stats__heading">
          Баланс счёта
        </div>
      </div>
    );
  };

  const content = () => {
    return (
      <div className="balance-stats__content">
        <div>
          {balanceAccount.map(balance => (
            <div className="balance-stats__row" key={balance.title}>
              <div className="balance-stats__row-container">
                <Tooltip content={balance.content}>
                  <div className="balance-stats__row-title">
                    {balance.title}
                  </div>
                </Tooltip>
              </div>
              <div className="balance-stats__right">
                {balance.string} <Money money={balance.count}/>
              </div>
            </div>
          ))}
          {balanceTotal.map(balance => (
            <div className="balance-stats__block-line" key={balance.title}>
              <div className="balance-stats__row-container">
                <Tooltip content={balance.content}>
                  <div className="balance-stats__row-title">{balance.title}</div>
                </Tooltip>
              </div>
              <div className="balance-stats__right">
                {balance.string} <Money money={{
                  amount: balance.count,
                  currencyCode: "RUB"
                }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="balance-stats">
      {isMobile
        ? <BlockDropdown header={header()}>
          {content()}
        </BlockDropdown>
        : <Block>
          <Block.Content>
            <Block.Header withPadding className="balance-stats__block-header">
              {header()}
            </Block.Header>
            <Block.Body className="balance-stats__block-body">
              {content()}
            </Block.Body>
          </Block.Content>
        </Block>
      }
    </div>
  );
};

export default BalanceStats;