import React, {memo} from "react";
import "./Header.scoped.scss";
import {CustomLink, TertiaryButton} from "components/common";
import {useLoggedInStatus, useToggle} from "hooks";
import {Button, Hamburger, SecondaryButton} from "../../common";
import {Container, LenderNavigation} from "../index";
import {HamburgerColor} from "../../common/utils/Hamburger/Hamburger";
import {SecondaryButtonColor} from "../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import ProfileMenu from "../../user/profile/ProfileMenu/ProfileMenu";
import {cn, resultIf, RouteDictionary} from "../../../utils";
import {LogoImage} from "../../common/images/types";
import {LogoType} from "../../common/images/types/LogoImage";
import NotificationLink from "../../common/buttons/decorators/NotificationLink";
import MobileNavigation from "../MobileNavigation";
import {TertiaryButtonColor} from "../../common/buttons/decorators/TertiaryButton/TertiaryButton";

type Props = {
    isPublic?: boolean;
}

const Header = ({isPublic}: Props) => {
  const [active, toggle, setActive] = useToggle();

  const isLoggedIn = useLoggedInStatus();
  return (
    <header className="header">
      <Container>
        <nav className="header__navigation">
          <div className="header__navigation__left">
            <div className="header__logo-container">
              <CustomLink className="index-link" to={RouteDictionary.INDEX}>
                <LogoImage className="header__logo" type={LogoType.GREEN} />
              </CustomLink>
            </div>
            { isPublic && <div className="header__projects">
              <CustomLink to={RouteDictionary.PRIMARY_MARKET} className="header__projects-link">
                <Hamburger rows={3} color={HamburgerColor.GREEN} />
                <div>
                  Проекты
                </div>
              </CustomLink>
            </div> }
            <ul className={cn("header__public-menu", resultIf(!isPublic, "header__logged-menu"))}>
              {/*{ isPublic && <>*/}
              {/*  <li className="header__public-element">*/}
              {/*    <CustomLink className="header__link header__link--public" to={RouteDictionary.HOW_IT_WORKS}>*/}
              {/*      Как это работает*/}
              {/*    </CustomLink>*/}
              {/*  </li>*/}
              {/*  <li className="header__public-element">*/}
              {/*    <CustomLink className="header__link header__link--public" to={RouteDictionary.PLATFORM}>*/}
              {/*      О платформе*/}
              {/*    </CustomLink>*/}
              {/*  </li>*/}
              {/*</> }*/}
              {/*<li className="header__public-element">*/}
              {/*    <CustomLink className="header__link header__link--public" to={RouteDictionary.NEWS}>*/}
              {/*        Новости*/}
              {/*    </CustomLink>*/}
              {/*</li>*/}
              {/*<li className="header__public-element">*/}
              {/*    <CustomLink className="header__link header__link--public" to={RouteDictionary.ABOUT_US}>*/}
              {/*        О нас*/}
              {/*    </CustomLink>*/}
              {/*</li>*/}
              {/*<li className="header__public-element">*/}
              {/*    <CustomLink className="header__link header__link--public" to={RouteDictionary.FAQ}>*/}
              {/*        { isPublic ? "FAQ" : "Помощь" }*/}
              {/*    </CustomLink>*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="header__navigation__right">
            { !isLoggedIn ? <div className="header__auth-menu">
              Войти как
              <SecondaryButton color={SecondaryButtonColor.GREEN}>
                <CustomLink to={RouteDictionary.ENTER} >
                  Инвестор
                </CustomLink>
              </SecondaryButton>
              или
              <SecondaryButton color={SecondaryButtonColor.ORANGE}>
                <Button onClick={() => console.log("123")}>
                  Заемщик
                </Button>
              </SecondaryButton>
            </div> : <div className="header__user-menu">
              <div className="header__notifications">
                <NotificationLink />
              </div>
              <div className="header__profile">
                <ProfileMenu />
              </div>
            </div> }
            <div className="header__mobile-open">
              <Hamburger onClick={toggle} rows={3} />
            </div>
          </div>
        </nav>
        <MobileNavigation active={active} onNavigated={() => setActive(false)} />
        { isLoggedIn && <>
          {active &&  <div className="mobile-navigation__money">
            <TertiaryButton expanded color={TertiaryButtonColor.GREEN}>
              <CustomLink  to={RouteDictionary.DEPOSIT}>
                Пополнить
              </CustomLink>
            </TertiaryButton>
            <TertiaryButton expanded color={TertiaryButtonColor.GRAY}>
              <CustomLink to={RouteDictionary.WITHDRAW}>
                Вывести
              </CustomLink>
            </TertiaryButton>
          </div>
          }
          <div className="header__mobile-profile">
            <ProfileMenu clickable={false} />
            <NotificationLink />
            <div
              className={cn("header__mobile-button", resultIf(active, "active"))}
              onClick={toggle}
            ></div>
          </div>
          <LenderNavigation />
        </> }
      </Container>
    </header>
  );
};

export default memo(Header);